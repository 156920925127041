<template>
  <div class="col-lg-3 col-md-4">

        <div class="fix-aside">
          <FilterByPrice></FilterByPrice>
          <FilterByCategory></FilterByCategory>
        </div>

  </div>
</template>

<script>
import FilterByPrice from "@/components/frontend/brand/filter_by/FilterByPrice";
import FilterByCategory from "@/components/frontend/brand/filter_by/FilterByCategory";
//import FixedHeader from "vue-fixed-header";
export default {
  components: {
    FilterByPrice,
    FilterByCategory,
//    FixedHeader
  },
};
</script>

<style>
.fix-aside{
  position: sticky;
  top: 150px;
}
</style>
