<template>
  <div class="product_aside">
    <h4 class="aside_title">Filter By Category</h4>
    <div class="product_menu">
      <form class="search_form" @submit.prevent="noUrl()">
        <div class="form-group">
          <input type="text" name="search_list"  v-model="brandCategoryFilter"/>
          <button type="button" class="sendBtn">
            <i class="icon ion-md-search"></i>
          </button>
        </div>
      </form>
      <ul class="menu_list">
        <li v-for="category in categorylist" :key="category.id" @click="filterByCat(category)">
           {{ category.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../../axios";
export default {
  name: "FilterByCategory",
  data(){
    return{
      selected:'',
      page:1,
      brandCategoryFilter:'',
      categorylist:''
    }
  },
  computed: {
    ...mapGetters(["brandCateory",'brand']),
  },
  async mounted(){
    this.categorylist = this.brandCateory;
  },
  watch:{
    $route (){
      axios.get(`${this.$route.fullPath}`).then((response) => {
        this.categorylist = response.data.data.categories;
      })
      this.brandCategoryFilter = '';

    },
    brandCategoryFilter(val) {
      if(val){
        let catFilter = this.brand.id+'?keyword='+val;
        axios.get(`/category-filter-in-brand/${catFilter}`).then((response) => {
          console.log("Filter", response.data.data);
          this.categorylist = response.data.data;
        })
      }else {
        let catFilter = this.brand.id+'?keyword='+'';
        axios.get(`/category-filter-in-brand/${catFilter}`).then((response) => {
          console.log("Filter", response.data.data);
          this.categorylist = response.data.data;
        })
        //this.categorylist = this.brandCateory;
      }

    },
  },
  methods: {
    filterByCat(category){
      this.$store.dispatch("setPage", this.page);
      let path_name =  'brand'
      console.log("brand slug up", this.$route.params.slug);
      this.$router.push({name: path_name, query: {...this.$route.query,page:this.page, category: category.id}}).catch(()=>{})
      /*const queryString = new URLSearchParams(this.$route.query).toString()
      console.log("brand queryString =", queryString);
      console.log("brand slug", this.$route.params.slug);
      this.$store.dispatch("getBrand", this.$route.params.slug + "?" + queryString)
      console.log('cat filter', this.$route.query );*/
      // this.$store.dispatch()
    },
    noUrl(){
      event.preventDefault();
    }
  }
};
</script>

<style>
  .menu_list li{
    cursor: pointer;
  }
</style>
