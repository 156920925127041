<template>
  <div>
    <SecondHeader :brand="brand"></SecondHeader>
    <BrandSection
      :categories="brand.categories"
      :products="brand.products"
    ></BrandSection>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SecondHeader from "@/components/frontend/brand/SecondHeader.vue";
import BrandSection from "@/components/frontend/brand/BrandSection.vue";

export default {
  name: "Category",
  metaInfo() {
    return {
        title: this.brand.meta_title,
        titleTemplate: '%s | Savers Hall',
      meta: [
        {
          name: 'description',
          content: this.brand.meta_description
        },
        {
          name: 'keyword',
          content: this.brand.meta_keyword
        }
      ]
    }
  },
  components: {
    SecondHeader,
    BrandSection,
  },
  computed: {
    ...mapGetters(["brand"]),
  },
  watch:{
    $route (){
      console.log("brand ===> ", this.$route.fullPath)
      this.$store.dispatch("getBrand", this.$route.fullPath);
    }
  },
  mounted() {
    //const slug = this.$route.params.slug;
    this.$store.dispatch("getBrand", this.$route.fullPath);
    console.log("Brand==>",this.brand)
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/category.scss";
</style>
