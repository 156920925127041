<template>
  <div class="product_aside">
    <h4 class="aside_title">Filter By Price</h4>
    <div>
      <vue-range-slider v-model="value" :min="min" @drag-end="ppp" :max="max" :enable-cross="enableCross"></vue-range-slider>
    </div>
    <div class="product_menu">
      <div class="price_filter">
        <div id="slider-range"></div>
        <div class="price_value">
          <div class="price">
            Price: <span id="slider-range-value1">{{value[0]}}</span> TK
          </div>
          <div class="price">
            Price: <span id="slider-range-value2">{{value[1]}}</span> TK
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueRangeSlider from 'vue-range-component'
  import {mapGetters} from "vuex";
  export default {
    components: {
      VueRangeSlider
    },
    data() {
      return {
        value: [0,5000]
      }
    },
    created() {
      this.min = 0
      this.max = 5000
      this.enableCross = false
      VueRangeSlider.methods.handleKeyup = ()=> console.log;
      VueRangeSlider.methods.handleKeydown = ()=> console.log;
    },
    computed: {
      ...mapGetters(["brand"]),
    },
    methods: {
      ppp(){
        // let queryParams = this.$route.query
        // queryParams.price = this.value[1]


        let path_name =  'brand'
        this.$router.push({name: path_name, query: {brand:this.brand.id,...this.$route.query, min_price:this.value[0], max_price:this.value[1]}}).catch(()=>{})
                /*.push({
                  name: path_name,
                  query: {category:cat.id, ...this.$route.query, min_price:this.value[0], max_price:this.value[1] },
                })*/
        /*console.log("Brand Price Route", this.$route.query)
        console.log("Brand Price Slug", this.$route.params.slug)
        const queryString = new URLSearchParams(this.$route.query).toString()
        this.$store.dispatch("getBrand", this.$route.params.slug + "?" + queryString)*/

        // this.$router.push( {  query: queryParams } )
        // .then(()=>{ this.$router.replace({ path:'category', param:{slug: 'Lipstick'}, query: queryParams } )})
        // .catch(()=>{})

        /*console.log('price filter', this.$route.params );*/

      }
    },
  };
</script>

<style></style>

<!--<template>
  <div class="product_aside">
    <h4 class="aside_title">Filter By Price</h4>
    <div class="product_menu">
      <div class="price_filter">
        <div id="slider-range"></div>
        <div class="price_value">
          <div class="price">
            Price: <span id="slider-range-value1"></span> TK
          </div>
          <div class="price">
            Price: <span id="slider-range-value2"></span> TK
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>-->
