<template>
  <header class="secound_header">
    <div class="container">
      <div class="header_img brand-img">
        <img :src="'https://savershall.com/suadmin/'+brand.banner" :alt="brand.logo_alt" />
      </div>
    </div>
  </header>
</template>

<script>
  /*import { mapGetters } from "vuex";*/
export default {
  props:["brand"],
  data() {
    return {
        slug:this.$route.params.slug
    };
  },
  /*computed: {
    ...mapGetters(["brand"]),
  },*/
  /*mounted() {
    /!*this.$store.dispatch('getBrand', this.slug);*!/
    console.log("Single Brand slug =", this.slug);
    console.log("Single Brand =", this.brand);
  },*/
  /*watch: {
    "$route.params.slug": {
      handler: function(value) {
        this.$store.dispatch('getBrand', value);
        console.log("Single Brand data =", this.brand);
      },
      deep: true,
      immediate: true,
    },
  },*/


};
</script>

<style></style>
